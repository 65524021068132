import { React } from 'react'
import { useLanguageContext } from "../../providers/LanguageProvider"

export default () => {
    const { t } = useLanguageContext();
    return <>

        <section id="contacts" className="contacts about">
            <ul className="contacts__nav nav">
                {t('nav').map((a, index) => <li key={index} className="nav__item"><a href={a.link}>{a.text}</a></li>)}
            </ul>

            <h2 className="contacts__title title-block">{t('contacts.title')}</h2>
            <div className="about__info about-info row gy-3 justify-content-center align-items-center">
                <div className="boxx about-info__text col-xl col-lg-7 col-md-6 text-left">
                    {t('contacts.info.items').map((text, index) => {
                        return <div key={index}>
                            <h3 className="text__title">{text.title}</h3>
                            <p className="text__block" dangerouslySetInnerHTML={{ __html: text.text }}></p>
                        </div>
                    })}
                </div>
                <div className="about-info__img col-xl col-lg col-md-5 col-sm-6 col-11">
                    <img src="img/icons/img-form.svg" alt="" />
                </div>
            </div>
        </section>
        {/*<section id="contacts" className="contacts">
            <ul className="contacts__nav nav">
                {t('nav').map((a, index) => <li key={index} className={"nav__item" + (a.link == '#contacts' ? ' active' : '')}><a href={a.link}>{a.text}</a></li>)}
            </ul>

            <h2 className="contacts__title title-block">{t('contacts.title')}</h2>

            <div className="contacts__main form-block row align-items-center">
                <form className="contacts__form col-md-6">
                    <div className="form-block__line">
                        <label className="form-block__label" for="name">{t('contacts.form.name')}</label>
                        <input className="form-block__input" id="name" type="text" placeholder={"|" + t('contacts.form.name')} />
                    </div>
                    <div className="form-block__line">
                        <label className="form-block__label" for="email">{t('contacts.form.email')}</label>
                        <input className="form-block__input" id="email" type="text" placeholder={"|" + t('contacts.form.email')} />
                    </div>
                    <div className="form-block__button justify-content-center">
                        <a type="submit" className="contacts-button _btn" href="#">{t('buttons.send')}</a>
                    </div>
                </form>
                <div className="form-block__img col-md-6 col-sm-6 col-11">
                    <img src="img/icons/img-form.svg" alt="" />
                </div>
            </div>
        </section>
        <section className="contacts__info contacts-info">
            <p className="contacts-info__title">{t('contacts.info.title')}</p>
            <ul className="contacts-info__block-contacts row gy-4">
                {t('contacts.info.items').map((ci, index) =>
                    <li key={index} className="col-lg col-md-12">
                        <p className="block-contacts__title">{ci.title}</p>
                        <p className="block-contacts__text">{ci.text}</p>
                    </li>
                )}
            </ul>
            </section>*/}
    </>
}