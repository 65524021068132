import { React } from 'react'
import { useLanguageContext } from '../../providers/LanguageProvider';

export default (props) => {
    const { project } = props;
    const { t } = useLanguageContext();
    return <div className="flip-card works__item">
        <div className='flip-card-inner'>
            <div className="flip-card-front box">
                <div className="item__img"><img src={project.image} /></div>
                <h2 className="box__title">
                    {project.title}
                </h2>
                <p className="box__text">
                    {project.text}
                </p>
            </div>
            <div className="flip-card-back box">
                <div className='works__item_desc'>
                    {project.description.map((item, index) => {
                        return <div key={index}>
                            {item.url && <a href={item.url} target="_blank">
                                {item.image && <img src={item.image} />}
                                {!item.image && <img src={project.image} />}
                                {!item.image && t('buttons.view')}
                            </a>}
                            {!item.url ? item.text : ''}
                        </div>
                    })}
                </div>
                <h2 className="box__title">{project.title}</h2>
                <p className="box__text">{project.text}</p>
            </div>
        </div>
    </div>
}