import { React } from 'react'
import { useLanguageContext } from "../../providers/LanguageProvider"

export default () => {
    const { t } = useLanguageContext();
    return <>
        <section id="services" className="services">
            <ul className="contacts__nav nav">
                {t('nav').map((a, index) => <li key={index} className={"nav__item" + (a.link == '#services' ? ' active' : '')}><a href={a.link}>{a.text}</a></li>)}
            </ul>
            <h2 className="services__title title-block">{t('services.title')}</h2>

            <div className="services__info row justify-content-center align-items-center">
                <div className="col-md-6">
                    <ul className="services__textx">
                        {t('services.items').map((text, index) => <div className="text__title" key={index} dangerouslySetInnerHTML={{ __html: text }}></div>)}
                    </ul>
                </div>
                <div className="services__img col-md-6 col-sm-6 col-11">
                    <img src="img/icons/img-center.svg" alt="" />
                </div>
            </div>
        </section>
    </>
}