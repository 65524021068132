import { useEffect, useState, useContext, createContext, useLayoutEffect } from "react";
import { React } from 'react'
//import Flag from 'react-world-flags'

import UK from "../languages/uk_ua";
import TR from "../languages/tr_TR";
import EN from "../languages/en_us";

export const LanguageContext = createContext({
    T: null,
    __: (key) => null,
    setLanguage: (key) => null,
    getLanguage: () => null,
    getSwitchers: () => null,
});

export const LanguageProvider = ({ children }) => {

    const languages = {
        uk: "Українська",
        tr: "Türkçe",
        en: "English",
    };

    const settings = {
        uk: UK,
        tr: TR,
        en: EN,
    };

    let callback;

    function getDefaultLanguage() {
        if (localStorage.language && Object.keys(settings).indexOf(localStorage.language) !== -1) {
            return localStorage.language;
        }
        return 'en';
    }

    const [language, setLanguage] = useState(getDefaultLanguage());
    const [T, setT] = useState(settings[language]);

    useEffect(() => {
        setT(settings[language]);
        localStorage.setItem('language', language);
        if (callback) callback(language);
    }, [language]);

    /*useLayoutEffect(() => {
        document.querySelector('html').setAttribute('lang', language);
    }, [language]);*/

    const getLanguage = () => language;

    const setl = (e) => {
        setLanguage(e.target.dataset.key);
        e.preventDefault();
    };
    const getSwitchers = () => {
        return <>
            <ul className="lang-list">
                {Object.keys(languages).map((l, index) => <li key={index} className={l === getLanguage() ? 'nav-item lang-item active' : 'nav-item lang-item'}><a href="#" onClick={setl} data-key={l}>{languages[l]}</a></li>)}
            </ul>
        </>
    };
    const t = (key) => {
        const keys = key.split('.');
        let obj = T;
        for (var k of keys) {
            if (obj[k]) obj = obj[k];
            else throw new Error(`Language Key [${k}] Not Found!`);
        }
        return obj;
    }

    const onChange = (cb) => { callback = cb }

    return (
        <LanguageContext.Provider value={{ t, setLanguage, getLanguage, getSwitchers, onChange }}>
            {children}
        </LanguageContext.Provider>
    );
}

export const useLanguageContext = () => useContext(LanguageContext);