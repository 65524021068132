export default {
    'nav': [
        {
            'link': '#about',
            'text': 'Hakkımızda',
        },
        {
            'link': '#services',
            'text': 'Hizmetler',
        },
        {
            'link': '#works',
            'text': 'Portföy',
        },
        {
            'link': '#contacts',
            'text': 'Kişiler'
        }
    ],
    'promo': {
        'title': 'CitySoft',
        'text': 'devlet düzeyinde program çözümlerinin geliştirilmesi',
    },
    'clients': {
        'title': 'Müşterilerimiz',
        'items': [
            { 'image': 'clients/mlt.png', 'title': 'Melitopol belediye meclisi' },
            { 'image': 'clients/ffn.png', 'title': 'FRIEDRICH NAUMANN FOUNDATION' },
            { 'image': 'clients/giz.png', 'title': 'GIZ GmbH' },
            { 'image': 'clients/arm.png', 'title': 'Melitopol Kalkınma Ajansı' },            
            { 'image': 'clients/eda24.png', 'title': 'Çevrimiçi yemek siparişi verin' },
        ]
    },
    'buttons': {
        'send': 'İstek gönder',
        'more': 'Daha fazla bilgi edin',
        'view': 'Gözden geçirmek'
    },
    'about': {
        'info': [
            {
                'title': 'şirket',
                'block': 'geniş bir yelpazede yazılım geliştirme hizmetleri sağlama alanında başarılı bir şekilde gelişmekte olan'
            },
            {
                'title': 'takım',
                'block': 'çok çeşitli yazılım geliştirme hizmetleri sağlama alanındaki profesyonelleri bir araya getiren'
            }
        ]
    },
    'services': {
        'title': 'Hizmetlerimiz',
        'items': [
            'web sitesi geliştirme',
            'CRM geliştirme',
            'mobil uygulamaların geliştirilmesi',
            'sitelerin tanıtımı',
            'internet pazarlamacılığı',
            'blockchain için geliştirme',
            'siber koruma'
        ]
    },
    'technologies': {
        'title': 'Kullandığımız teknolojiler',
        'items': [
            { 'image': 'img/new-php-logo.svg', 'alt': '' },
            { 'image': 'img/technologists-1.svg', 'alt': '' },
            { 'image': 'img/technologists-2.svg', 'alt': '' },
            { 'image': 'img/technologists-3.svg', 'alt': '' },
            { 'image': 'img/technologists-4.svg', 'alt': '' },
            { 'image': 'img/technologists-5.svg', 'alt': '' },
        ]
    },
    'works': {
        'title': 'Bizim isimiz',
        'items': [
            {
                'title': 'MELITOPOL TEK\'tir',
                'text': 'İş destek portalı',
                'image': 'img/our-works-4.jpg',
                'description': [
                    { url: 'https://biz.mlt.gov.ua' }
                ]
            },
            {
                'title': 'Melitopol belediye meclisi',
                'text': 'Resmi İnternet portalı',
                'image': 'img/our-works-1.jpg',
                'description': [
                    { url: 'https://mlt.gov.ua' }
                ]
            },
            {
                'title': 'Melitopol Smart City',
                'text': 'Mobil uygulama',
                'image': 'img/our-works-3.jpg',
                'description': [
                    { image: 'aivalable-on-the-app-store-2.svg', url: 'https://apps.apple.com/ua/app/melitopol-smart-city/id1485118098?l=uk' },
                    { image: 'google-play-4.svg', url: 'https://play.google.com/store/apps/details?id=ltd.sender.mymlt&hl=uk' }
                ]
            },
            {
                'title': 'EDA24',
                'text': 'Çevrimiçi yemek siparişi hizmeti',
                'image': 'img/our-works-2.jpg',
                'description': [
                    { url: 'https://eda24.com.ua' }
                ]
            },
        ]
    },
    'contacts': {
        'title': 'Kişiler',
        'form': {
            'name': 'Adınız',
            'email': 'E-posta adresiniz'
        },
        'info': {
            'title': 'İletişim bilgileri',
            'items': [
                { 'title': 'Telegram', 'text': '<a href="https://t.me/citysoft_online" target="_blank">@citysoft_online</a>' },
                { 'title': 'Email', 'text': '<a href="mailto:support@citysoft.online" target="_blank">support@citysoft.online</a>' },
            ]
        }
    },

    'footer': {
        'nav_title': 'Навігація',
        'title': 'об’єднує професіоналів у сфері надання широкого спектра послуг з розробки програмного забезпечення, компанія що успішно розвивається у сфері надання широкого спектра послуг з розробки програмного забезпечення'
    }
}