export default {
    'nav': [
        {
            'link': '#about',
            'text': 'Про нас',
        },
        {
            'link': '#services',
            'text': 'Послуги',
        },
        {
            'link': '#works',
            'text': 'Портфоліо',
        },
        {
            'link': '#contacts',
            'text': 'Контакти'
        }
    ],
    'promo': {
        'title': 'CitySoft',
        'text': 'розробка програмних рішень державного рівня',
    },
    'clients': {
        'title': 'Наші клієнти',
        'items': [
            { 'image': 'clients/mlt.png', 'title': 'Мелітопольська міська рада' },
            { 'image': 'clients/ffn.png', 'title': 'FRIEDRICH NAUMANN FOUNDATION' },
            { 'image': 'clients/giz.png', 'title': 'GIZ GmbH' },
            { 'image': 'clients/arm.png', 'title': 'Агенція розвитку Мелітополя' },            
            { 'image': 'clients/eda24.png', 'title': 'Замовлення їжі онлайн' },
        ]
    },
    'buttons': {
        'send': 'Надіслати запит',
        'more': 'Дізнатись більше',
        'view': 'Переглянути'
    },
    'about': {
        'info': [
            {
                'title': 'компанія',
                'block': 'що успішно розвивається у сфері надання широкого спектра послуг з розробки програмного забезпечення'
            },
            {
                'title': 'команда',
                'block': 'що об’єднує професіоналів у сфері надання широкого спектра послуг з розробки програмного забезпечення'
            }
        ]
    },
    'services': {
        'title': 'Наші послуги',
        'items': [
            'розробка сайтів',
            'розробка CRM',
            'розробка мобільних додатків',
            'просування сайтів',
            'інтернет маркетинг',
            'розробка для блокчейн',
            'кібер-захист'
        ]
    },
    'technologies': {
        'title': 'Технології, що ми використовуємо',
        'items': [
            { 'image': 'img/new-php-logo.svg', 'alt': '' },
            { 'image': 'img/technologists-1.svg', 'alt': '' },
            { 'image': 'img/technologists-2.svg', 'alt': '' },
            { 'image': 'img/technologists-3.svg', 'alt': '' },
            { 'image': 'img/technologists-4.svg', 'alt': '' },
            { 'image': 'img/technologists-5.svg', 'alt': '' },
        ]
    },
    'works': {
        'title': 'Наші роботи',
        'items': [
            {
                'title': 'МЕЛІТОПОЛЬ - ЄДИНИЙ',
                'text': 'Портал підтримки бізнесу',
                'image': 'img/our-works-4.jpg',
                'description': [
                    { url: 'https://biz.mlt.gov.ua' }
                ]
            },
            {
                'title': 'Мелітопольська міська рада',
                'text': 'Офіційний інтернет-портал',
                'image': 'img/our-works-1.jpg',
                'description': [
                    { url: 'https://mlt.gov.ua' }
                ]
            },
            {
                'title': 'Melitopol Smart City',
                'text': 'Мобильний додаток',
                'image': 'img/our-works-3.jpg',
                'description': [
                    { image: 'aivalable-on-the-app-store-2.svg', url: 'https://apps.apple.com/ua/app/melitopol-smart-city/id1485118098?l=uk' },
                    { image: 'google-play-4.svg', url: 'https://play.google.com/store/apps/details?id=ltd.sender.mymlt&hl=uk' }
                ]
            },
            {
                'title': 'EDA24',
                'text': 'Сервіс замовлення іжі онлайн',
                'image': 'img/our-works-2.jpg',
                'description': [
                    { url: 'https://eda24.com.ua' }
                ]
            },
        ]
    },
    'contacts': {
        'title': 'Контакти',
        'form': {
            'name': 'Ваше ім’я',
            'email': 'Ваш e-mail'
        },
        'info': {
            'title': 'Контактна інформація',
            'items': [
                { 'title': 'Telegram', 'text': '<a href="https://t.me/citysoft_online" target="_blank">@citysoft_online</a>' },
                { 'title': 'Email', 'text': '<a href="mailto:support@citysoft.online" target="_blank">support@citysoft.online</a>' },
            ]
        }
    },

    'footer': {
        'nav_title': 'Навігація',
        'title': 'об’єднує професіоналів у сфері надання широкого спектра послуг з розробки програмного забезпечення, компанія що успішно розвивається у сфері надання широкого спектра послуг з розробки програмного забезпечення'
    }
}