import { React } from 'react'
import { useLanguageContext } from "../../providers/LanguageProvider"
export default () => {
    const { t } = useLanguageContext();
    return <>
        <section id="about" className="section about">
            <ul className="contacts__nav nav">
                {t('nav').map((a, index) => <li key={index} className="nav__item"><a href={a.link}>{a.text}</a></li>)}
            </ul>
            <h2 className="about__title title-block">CitySoft</h2>
            <div className="about__info about-info row gy-3 justify-content-between">
                <div className="about-info__img col-xlx col-lg-5 col-md col-sm-6 col-11">
                    <img src="img/icons/hands.svg" />
                </div>

                <div className="about-info__text col-xlx col-lg-6 col-md-8 col-sm-12 ">
                    {t('about.info').map((text, index) => {
                        return <div key={index}>
                            <h3 className="text__title">{text.title}</h3>
                            <p className="text__block">{text.block}</p>
                        </div>
                    })}
                </div>
            </div>
            <div className="about__button">
                <a href="CV-CitySoft-FOP.pdf" target="_blank" className="about-button btn">{t('buttons.more')}</a>
            </div>
        </section>
    </>
}