import { React, useEffect, useState } from 'react'
import { useLanguageContext } from "../../providers/LanguageProvider";
import ReactTypingEffect from 'react-typing-effect';


export default () => {
    const { t, getSwitchers } = useLanguageContext();
    const promoText = [t('promo.text'), ...t('services.items')].map((t) => "- " + t);

    return <>
        <section className="section promo row">
            <div className="section__info promo__info col-lg-6 col-md-7 col-sm-10">
                <div className="promo__info_full">
                    <h1 className="promo__title">
                        {t('promo.title')}
                    </h1>
                    <div className="promo__text">
                        {/*t('promo.text')*/}
                        {<ReactTypingEffect text={promoText} typingDelay={100} speed={150} eraseSpeed={50} eraseDelay={1500} />}
                        {/*<ReactTypingEffect className="small" cursorRenderer={cursor => <span>{cursor}</span>} text={t('services.items').map((t) => "- " + t)} />*/}
                    </div>
                </div>

                {getSwitchers()}
            </div>
            <div className="section__img promo__img col-lg-6 col-md col-sm-9 col-11">
                <img src="img/icons/img-header.svg" />
            </div>
        </section>
    </>
}