import './assets/scss/style.scss';
//import './assets/scss/new/style.scss';

import { React } from 'react'
import Header from './components/header';
import Footer from './components/footer';
//import { useLanguageContext } from './providers/LanguageProvider';
import MainPage from './pages/MainPage';


//TODO
//import Skeleton from 'react-loading-skeleton';
//import 'react-loading-skeleton/dist/skeleton.css';


function App() {
  //const { t } = useLanguageContext();
  return (
    <div className="wrapper">
      <Header />
      <MainPage />
      <Header />
    </div>
  );
}

export default App;
