import { React, useLayoutEffect } from 'react'
import { useState } from "react";
import { useLanguageContext } from "../providers/LanguageProvider";

export default () => {
    const { t, getSwitchers } = useLanguageContext();
    const [showMenu, setShowMenu] = useState(0);
    const toggleMenu = () => setShowMenu(!showMenu);
    useLayoutEffect(() => {
        document.body.className = showMenu ? 'lock' : '';
    }, [showMenu]);

    return (
        <header className="header">
            <div className="header__body row">
                <a href="#" className="header__logo col-md-4 col-sm-9 col">
                    <img src="img/icons/logo+text.svg" alt="CitySoft" />
                </a>
                <div className="header__none col-md-8 col-sm-4 col-6">
                    <div className={"header__burger" + (showMenu ? " active" : "")} onClick={toggleMenu}><span></span></div>
                    <nav className={"header__menu" + (showMenu ? " active" : "")}>
                        <ul className="header__list">
                            {t('nav').map((nav, index) => <li key={index} className="nav-item" onClick={toggleMenu}><a href={nav.link}>{nav.text}</a></li>)}
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
};