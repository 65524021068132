import { React } from 'react'
import Promo from '../components/sections/Promo';
import Clients from '../components/sections/Clients';
import About from '../components/sections/About';
import Services from '../components/sections/Services';
import Technologies from '../components/sections/Technologies';
import Works from '../components/sections/Works';
import Contacts from '../components/sections/Contacts';
//import { useParallax, Parallax, ParallaxProvider } from 'react-scroll-parallax';

export default () => {
    return <>
        <main className="main">
            <Promo />
            <Clients />
            <About />
            <Services />
            <Technologies />
            <Works />
            <Contacts />
        </main>
    </>
}