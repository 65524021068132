import { React } from 'react'
import { useLanguageContext } from "../../providers/LanguageProvider"
import { Rerousel } from "rerousel";
import { useRef } from "react";
import Marquee from "react-fast-marquee";
import WorksItem from './WorksItem';

export default () => {
    const { t } = useLanguageContext();
    const ref = useRef(null);
    return <>
        <section id="works" className="section works">
            <ul className="contacts__nav nav">
                {t('nav').map((a, index) => <li key={index} className={"nav__item" + (a.link == '#works' ? ' active' : '')}><a href={a.link}>{a.text}</a></li>)}
            </ul>
            <h2 className="works__title title-block">{t('works.title')}</h2>
            <ul className="works__list">
                {t('works.items').map((project, index) => <WorksItem key={index} project={project} />)}
            </ul>
        </section>
    </>
}