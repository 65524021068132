export default {
    'nav': [
        {
            'link': '#about',
            'text': 'About us',
        },
        {
            'link': '#services',
            'text': 'Services',
        },
        {
            'link': '#works',
            'text': 'Portfolio',
        },
        {
            'link': '#contacts',
            'text': 'Contacts'
        }
    ],
    'promo': {
        'title': 'CitySoft',
        'text': 'development of state-level software solutions',
        //'text': 'state-level software development',
    },
    'clients': {
        'title': 'Our Clients',
        'items': [
            { 'image': 'clients/mlt.png', 'title': 'Melitopol City Council' },
            { 'image': 'clients/ffn.png', 'title': 'FRIEDRICH NAUMANN FOUNDATION' },
            { 'image': 'clients/giz.png', 'title': 'GIZ GmbH' },
            { 'image': 'clients/arm.png', 'title': 'Melitopol Development Agency' },            
            { 'image': 'clients/eda24.png', 'title': 'Food ordering online' },
        ]
    },
    'buttons': {
        'send': 'Send request',
        'more': 'Learn more',
        'view': 'View'
    },
    'about': {
        'info': [
            {
                'title': 'company',
                'block': 'which is successfully developing in the field of providing a wide range of software development services'
            },
            {
                'title': 'team',
                'block': 'which brings together professionals in the field of providing a wide range of software development services'
            }
        ]
    },
    'services': {
        'title': 'Our Services',
        'items': [
            'site development',
            'CRM development',
            'mobile apps development',
            'site promotion',
            'internet marketing',
            'blockchain development',
            'cyber-security'
        ]
    },
    'technologies': {
        'title': 'Technologies we use',
        'items': [
            { 'image': 'img/new-php-logo.svg', 'alt': '' },
            { 'image': 'img/technologists-1.svg', 'alt': '' },
            { 'image': 'img/technologists-2.svg', 'alt': '' },
            { 'image': 'img/technologists-3.svg', 'alt': '' },
            { 'image': 'img/technologists-4.svg', 'alt': '' },
            { 'image': 'img/technologists-5.svg', 'alt': '' },
        ]
    },
    'works': {
        'title': 'Our works',
        'items': [
            {
                'title': 'MELITOPOL - UNITED',
                'text': 'Business support portal',
                'image': 'img/our-works-4.jpg',
                'description': [
                    { url: 'https://biz.mlt.gov.ua' }
                ]
            },
            {
                'title': 'Melitopol City Council',
                'text': 'Official Internet Portal',
                'image': 'img/our-works-1.jpg',
                'description': [
                    { url: 'https://mlt.gov.ua' }
                ]
            },
            {
                'title': "Smart city of Melitopol",
                'text': 'Mobile App',
                'image': 'img/our-works-3.jpg',
                'description': [
                    { image: 'aivalable-on-the-app-store-2.svg', url: 'https://apps.apple.com/ua/app/melitopol-smart-city/id1485118098?l=uk' },
                    { image: 'google-play-4.svg', url: 'https://play.google.com/store/apps/details?id=ltd.sender.mymlt&hl=uk' }
                ]
            },
            {
                'title': 'EDA24',
                'text': 'Online food order service',
                'image': 'img/our-works-2.jpg',
                'description': [
                    { url: 'https://eda24.com.ua' }
                ]
            },
        ]
    },
    'contacts': {
        'title': 'Contacts',
        'form': {
            'name': 'Your name',
            'email': 'Your email'
        },
        'info': {
            'title': 'Contact Information',
            'items': [
                { 'title': 'Telegram', 'text': '<a href="https://t.me/citysoft_online" target="_blank">@citysoft_online</a>' },
                { 'title': 'Email', 'text': '<a href="mailto:support@citysoft.online" target="_blank">support@citysoft.online</a>' },
            ]
        }
    },

    'footer': {
        'nav_title': 'Navigation',
        'title': 'brings together professionals in the field of providing a wide range of software development services, a company that is successfully developing in the field of providing a wide range of software development services'
    }
}