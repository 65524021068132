import { React } from 'react'
import { useLanguageContext } from '../../providers/LanguageProvider';
import Marquee from "react-fast-marquee";

export default () => {
    const { t } = useLanguageContext();
    return <>
        <section className="box clients">
            <h2 id="clients" className="box__title">{t("clients.title")}</h2>
            <ul className="box__list box__slider">
                <Marquee pauseOnHover={!true} speed={20} gradient={false}>
                    {t('clients.items').map((t, index) => {
                        return <li key={index}><img src={t.image} alt={t.title} /><span style={t.title.length > 30 ? { fontSize: '12px' } : {}}>{t.title}</span></li>
                    })}
                </Marquee>
            </ul>
        </section>
    </>
}